// React Library
import React, {Component} from 'react';

// Import SCSS
import './scss/QuickTask.scss';
import {connect} from "react-redux";

// Import Taskbar
import Taskbar from './taskbar/taskbar';



class QuickTask extends Component {
    

    render() {

        return (


            <div className="app-table container">
                <div className="center-table">
                    <h2>Quick Tasks</h2>
                    <Taskbar />
                    <table className="gfg">

                        <thead>
                        <tr>
                            <th className="heading__cell desc__wide">Product</th>
                            <th className="heading__cell">Store</th>
                            <th className="heading__cell">Mode</th>
                            <th className="heading__cell">Delay</th>
                            <th className="heading__cell">Proxy</th>
                            <th className="heading__cell">Profile</th>
                            <th className="heading__cell">Status</th>
                            <th className="heading__cell">Action</th>
                        </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>ColeWorld</td>
                                <td>ColeWorld</td>
                                <td>ColeWorld</td>
                                <td>ColeWorld</td>
                                <td>ColeWorld</td>
                                <td>ColeWorld</td>
                                <td>ColeWorld</td>
                                <td>ColeWorld</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        )
    }
}


export default QuickTask;