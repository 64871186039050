// React Library
import React, {Component} from 'react';

// Import SCSS
import '../proxy/scss/tableStyle.scss';
import '../proxy/scss/rowStyle.scss';

import {Play, Square, Copy, Trash} from "react-feather";
import {connect} from "react-redux";
import tasks from "../../api/tasks";

class Row extends Component {
    state = {
        success:0,
        carted:0,
        failed:0
    }

    render() {

        const deleteTask = () =>{
            this.props.dispatch(tasks.del(this.props.task))
        }

        const duplicateTask = () => {
            console.log("not working")
            //this.props.duplicateTask()
        }
        const startTask = () => {
            this.props.dispatch(tasks.start(this.props.task))
        }
 
        let product = this.props.task.name ? <a>{this.props.task.name} ({this.props.task.threads})</a> : <a>{this.props.task.product} ({this.props.task.threads})</a>
        let success = <a style={{ color: 'green' }}>{this.state.success}</a>
        let carted = <a style={{ color: 'orange' }}>{this.state.carted}</a>
        let failed = <a style={{ color: 'red' }}>{this.state.failed}</a>
        return (
            <tr>
                <td className="desc__wide">{product}</td>
                <td>{this.props.task.store}</td>
                <td>{this.props.task.mode}</td>
                <td>{this.props.task.delay}ms</td>
                <td>{this.props.task.proxy}</td>
                <td>{this.props.task.profile}</td>
                {/*

                    <td>{success}  {carted}  {failed}</td>
                */}<td>(check console)</td>

                <td>
                    <button className='row-button' onClick={() => {startTask()}}><Play/></button>
                    <button className='row-button' onClick={() => {console.log("STOP TASK")}}> <Square/></button>
                    <button className='row-button' onClick={() => {duplicateTask()}}><Copy/></button>
                    <button className='row-button' onClick={() => {deleteTask()}}><Trash/></button>
                </td>
            </tr>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        dispatch
    }
}

export default connect(null,mapDispatchToProps)(Row);