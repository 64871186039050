// React Library
import React, {Component} from 'react';

// Import SCSS
import './scss/tableStyle.scss';
import Row from './Row';
import {connect} from "react-redux";
import api from '../../api/tasks.js';
 




class Table extends Component {
    state = {}

    componentDidMount() {
        this.props.dispatch(api.fetch())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
       // console.log(this.props)
    };

    
    


    render() {

        let Rows;
        Rows = this.props.proxies.map(proxy => {
                return (
                    <Row proxy={proxy}/>
                )
            }
        )


        return (

                    <table className="gfg" id="task-main-table">

                        <thead>
                        <tr>
                            <th className="heading__cell desc__wide">Proxy Name</th>
                            <th className="heading__cell">Length</th>
                            <th className="heading__cell">Actions</th>

                        </tr>
                        </thead>

                        <tbody>
                            {Rows}
                        </tbody>

                    </table>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        proxies: state.proxies
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        dispatch
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Table);