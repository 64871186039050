import React from 'react';


// Import SCSS Files
import './scss/style.scss';
import './scss/_variables.scss';
import './scss/_globals.scss';



// Import Logo
import Logo from '../../images/logo-transparent.png'

// Import redux connect
import { connect } from "react-redux";
import {NavLink} from "react-router-dom";




const HomeNavBar = ({user}) => {
    
    return(
        <div className="home-navbar-app">

            {/* Header */}

            <header className="header">


                {/* Overlay */}
                <div className="overlay has-fade"/>

                {/* Navbar */}
                <nav className="flex flex-jc-sb flex-ai-c container">

                    {/* Logo */}
                    <a href='/' className="header_logo">
                        <img src={Logo} alt="Havoc" className="logo-header"/>
                    </a>

                    

                    {/* Main Heading Links on Desktop View */}

                    <div className="header__links hide-for-mobile">
                        <NavLink to="/" >
                            <a href="#" ><i className="icon-a fas fa-tasks"/> Tasks</a>
                        </NavLink>

                        {/*
                            <NavLink to="/monitor">
                                <a href="#"><i className="icon-a fas fa-tv"/> Monitor</a>
                            </NavLink>

                            <NavLink to="/quick">
                            <a href="#" ><i className="icon-a fas fa-check"/> Quick Tasks</a>
                            </NavLink>
                        */}

                        <NavLink to="/proxy">
                            <a href="#" ><i className="icon-a fas fa-random"/> Proxies</a>
                        </NavLink>

                        <NavLink to="/profiles" >
                        <a href="#" ><i className="icon-a fas fa-users"/> Profiles</a>
                        </NavLink>

                        <NavLink to="/settings">
                            <a href="#" ><i className="icon-a fas fa-cogs"/> Settings</a>
                        </NavLink>

                    </div>






                    {/* Desktop Gradient Button */}


                    <a className="nav-button header__cta hide-for-mobile">Welcome, { user.name }</a>


                </nav>

                

            </header>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default connect(mapStateToProps,null)(HomeNavBar);