// React Library
import React, {Component} from 'react';

// Import SCSS
import './scss/monitorStyle.scss';
import {connect} from "react-redux";

// Import Modal
import MonitorModal from "../modal/MonitorModal";


class Monitor extends Component {

    render() {


        return (


            <div className="app-table container">
                <div className="center-table">
                    <h2>Monitor</h2>
                    <table className="gfg">

                        <thead>
                        <tr>
                            <th className="heading__cell desc__wide">Site</th>
                            <th className="heading__cell">Product</th>
                            <th className="heading__cell">Proxy</th>
                            <th className="heading__cell">Delay</th>
                            <th className="heading__cell">Threads</th>
                            <th className="heading__cell">Profile</th>
                        </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td className="heading__cell desc__wide">js</td>
                                <td>js</td>
                                <td>js</td>
                                <td>js</td>
                                <td>js</td>
                                <td>js</td>
                            </tr>
                        </tbody>
                    

                    </table>

                    <MonitorModal />
                    
                </div>
                
            </div>
        )
    }
}



export default Monitor;
