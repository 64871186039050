// React Library
import React, {Component} from 'react';

// Import SCSS
import './scss/profileStyle.scss';
import {connect} from "react-redux";

// Import Proxy Modal (will do differently in version 2.0)
import ProfileModal from "../modal/ProfileModal";
import ProfileTable from "./Table"
import profiles from "../../api/profiles";




class ProxyTable extends Component {

    componentDidMount() {
        this.props.dispatch(profiles.fetch())
    }

    render() {

        return (

            <div className="app-table container">
                
                <div className="center-table">
                    <h2>Profiles</h2>


                   <ProfileTable />
                    <div className="proxy-taskbar">
                        <ProfileModal />
                    </div>

                </div>
            </div>
        )
    }
}


const mapStateToProps = (props) => {
    return {
        tasks: props.tasks,
        modules: props.modules,
        profiles: props.profiles,
        proxies: props.proxies
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        dispatch
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ProxyTable);

