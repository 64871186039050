// React Library
import React, {Component} from 'react';

// Import SCSS
import '../proxy/scss/tableStyle.scss';
import Row from './Row';
import {connect} from "react-redux";
import api from '../../api/tasks.js';
 
// Import Taskbar
import Taskbar from './taskbar/taskbar';
import proxies from "../../api/proxies";
import tasks from "../../api/tasks";
import profiles from "../../api/profiles";
import modules from "../../api/modules";



class Table extends Component {
    state = {}

    componentDidMount() {
        this.props.dispatch(proxies.fetch())
        this.props.dispatch(tasks.fetch())
        this.props.dispatch(profiles.fetch())
        this.props.dispatch(modules.fetch())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
       // console.log(this.props)
    };

    
    


    render() {

        let Rows;
        if (this.props.tasks != null){
            Rows = this.props.tasks.map(task => {
                    return (
                        <Row task={task}/>
                    )
                }
            )
        }

        return (


            <div className="app-table container">
                <div className="center-table">
                    <h2>Releases</h2>
                    <Taskbar tasks={this.props.tasks}/>
                    <table className="gfg" id="task-main-table">

                        <thead>
                        <tr>
                            <th className="heading__cell desc__wide">Product</th>
                            <th className="heading__cell">Store</th>
                            <th className="heading__cell">Mode</th>
                            <th className="heading__cell">Delay</th>
                            <th className="heading__cell">Proxy</th>
                            <th className="heading__cell">Profile</th>
                            <th className="heading__cell">Status</th>
                            <th className="heading__cell">Action</th>
                        </tr>
                        </thead>

                        <tbody>
                        {Rows}
                        </tbody>

                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tasks: state.tasks
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        dispatch
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Table);