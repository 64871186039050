// Modal Basic Layout on User Dash

// React Library
import React, {Component} from 'react';

// import toast notifications
import { ToastProvider, useToasts } from 'react-toast-notifications';

// Import Modal SCSS
import './scss/modalStyle.scss';

// Using react-bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';

import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';

import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { Cpu } from 'react-feather';

import profiles from "../../api/profiles";
import {connect} from "react-redux";

class VerticallyCenteredModal extends Component {
    state = {
        name:"",
        email:"",
        phone_prefix:"",
        phone_main:"",

        billing_address_first_name:"",
        billing_address_last_name:"",
        billing_address_line_1:"",
        billing_address_line_2:"",
        billing_address_postcode:"",
        billing_address_city:"",
        billing_address_county:"",
        billing_address_country:"",

        delivery_address_first_name:"",
        delivery_address_last_name:"",
        delivery_address_line_1:"",
        delivery_address_line_2:"",
        delivery_address_postcode:"",
        delivery_address_city:"",
        delivery_address_county:"",
        delivery_address_country:"",

        card_first_name:"",
        card_last_name:"",
        card_card_number:"",
        card_expiry_month:"",
        card_expiry_year:"",
        card_cvv:"",
    }
    render() {

        const submitChange = (e) => {
            e.preventDefault()

            let profile = {
                name:this.state.name,
                email:this.state.email,
                phone_prefix:this.state.phone_prefix,
                phone_main:this.state.phone_main,
                delivery_address:{
                    first_name:this.state.delivery_address_first_name,
                    last_name:this.state.delivery_address_last_name,
                    line_1:this.state.delivery_address_line_1,
                    line_2:this.state.delivery_address_line_2,
                    postcode:this.state.delivery_address_postcode,
                    city:this.state.delivery_address_city,
                    county:this.state.delivery_address_county,
                    country:this.state.delivery_address_country
                },
                billing_address:{
                    first_name:this.state.billing_address_first_name,
                    last_name:this.state.billing_address_last_name,
                    line_1:this.state.billing_address_line_1,
                    line_2:this.state.billing_address_line_2,
                    postcode:this.state.billing_address_postcode,
                    city:this.state.billing_address_city,
                    county:this.state.billing_address_county,
                    country:this.state.billing_address_country
                },
                card:{
                    first_name:this.state.billing_address_first_name,
                    last_name:this.state.billing_address_last_name,
                    card_number:parseInt(this.state.card_card_number),
                    expiry_month:parseInt(this.state.card_expiry_month),
                    expiry_year:parseInt(this.state.card_expiry_year),
                    cvv:parseInt(this.state.card_cvv),
                }
            }

            if (profile.delivery_address.line_1 === ""){
                profile.delivery_address = profile.billing_address
            }

            console.log(profile)
            this.props.dispatch(profiles.create(profile))
        }
        const changeState = (e) => {
            const newValue = e.target.value
            this.setState({
                [e.target.id]: newValue
            })
        }

        return (
            <div className="">
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="use-bootstrap"
                    style={{
                        "color": "black"
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Add New Profile
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body onChange={changeState}>
                        <h4>Profile</h4>

                        <Container>
                            <form>

                                <Row>
                                    <Col>
                                        <Form.Group controlId="name">
                                            <Form.Label>Profile Name</Form.Label>
                                            <Form.Control type="text" placeholder="Profile"/>
                                        </Form.Group>

                                        <Form.Group controlId="email">
                                            <Form.Label>Email </Form.Label>
                                            <Form.Control type="text" placeholder="name@example.com"/>
                                        </Form.Group>

                                        <Form.Group controlId="phone_prefix">
                                            <Form.Label>Phone Prefix</Form.Label>
                                            <Form.Control type="text" placeholder="44"/>
                                        </Form.Group>

                                        <Form.Group controlId="phone_main">
                                            <Form.Label>Phone Main </Form.Label>
                                            <Form.Control type="text" placeholder="790000000"/>
                                        </Form.Group>

                                    </Col>

                                    <Col>

                                        <Form.Group controlId="card_card_number">
                                            <Form.Label>Card Number</Form.Label>
                                            <Form.Control type="number" placeholder="#### #### #### ####"/>
                                        </Form.Group>

                                        <Row>
                                            <Col>
                                                <Form.Group controlId="card_expiry_month">
                                                    <Form.Label>Expiry Month</Form.Label>
                                                    <Form.Control type="number" placeholder="02"/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="card_expiry_year">
                                                    <Form.Label>Expiry Year</Form.Label>
                                                    <Form.Control type="number" placeholder="2025"/>
                                                </Form.Group>
                                                <Form.Group controlId="card_cvv">
                                                    <Form.Label>CVV</Form.Label>
                                                    <Form.Control type="number" placeholder="Security Code"/>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>

                                <Dropdown.Divider/>

                                <Row>

                                    <Col lg="5" className="text-center">
                                        <h5>Billing Address</h5>

                                        <Row>
                                            <Col>
                                                <Form.Group controlId="billing_address_first_name">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type="text" placeholder="First Name"/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="billing_address_last_name">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Last Name"/>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Form.Group controlId="billing_address_line_1">
                                            <Form.Label></Form.Label>
                                            <Form.Control type="text" placeholder="Address Line 1"/>
                                        </Form.Group>

                                        <Form.Group controlId="billing_address_line_2">
                                            <Form.Label></Form.Label>
                                            <Form.Control type="text" placeholder="Address Line 2"/>
                                        </Form.Group>

                                        <Row>
                                            <Col>
                                                <Form.Group controlId="billing_address_county">
                                                    <Form.Label>County</Form.Label>
                                                    <Form.Control type="text" placeholder="County"/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="billing_address_country">
                                                    <Form.Label>Country</Form.Label>
                                                    <Form.Control type="text" placeholder="Country"/>
                                                </Form.Group>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col>
                                                <Form.Group controlId="billing_address_city">
                                                    <Form.Label>City</Form.Label>
                                                    <Form.Control type="text" placeholder="City"/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="billing_address_postcode">
                                                    <Form.Label>Postal/Zip Code</Form.Label>
                                                    <Form.Control type="text" placeholder="Code"/>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                    </Col>

                                    <Col className="v-divider">

                                    </Col>

                                    <Col lg="5" className="text-center">
                                        <h5>Delivery Address</h5>

                                        <Row>
                                            <Col>
                                                <Form.Group controlId="delivery_address_first_name">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type="text" placeholder="First Name"/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="delivery_address_last_name">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Last Name"/>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Form.Group controlId="delivery_address_line_1">
                                            <Form.Label></Form.Label>
                                            <Form.Control type="text" placeholder="Address Line 1"/>
                                        </Form.Group>

                                        <Form.Group controlId="delivery_address_line_2">
                                            <Form.Label></Form.Label>
                                            <Form.Control type="text" placeholder="Address Line 2"/>
                                        </Form.Group>

                                        <Row>
                                            <Col>
                                                <Form.Group controlId="delivery_address_county">
                                                    <Form.Label>State</Form.Label>
                                                    <Form.Control type="text" placeholder="County"/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="delivery_address_country">
                                                    <Form.Label>Country</Form.Label>
                                                    <Form.Control type="text" placeholder="Country"/>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Group controlId="delivery_address_city">
                                                    <Form.Label>City</Form.Label>
                                                    <Form.Control type="text" placeholder="City"/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="delivery_address_postcode">
                                                    <Form.Label>Postal/Zip Code</Form.Label>
                                                    <Form.Control type="text" placeholder="Code"/>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                    </Col>

                                </Row>


                            </form>


                        </Container>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={this.props.onHide}>Close</Button>
                        <Button onClick={submitChange} >Submit</Button>
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
}

const ModalWrapper = ({dispatch}) => {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <div className="use-bootstrap" >
            <Button variant="primary" onClick={() => setModalShow(true)}>
                Create  ➕
            </Button>

            <VerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                dispatch={dispatch}
            />
        </div>
    );
}


function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts()
        return (
            <Component {...props} {...toastFuncs} />
        )
    }
}

const mapStateToProps = (props) => {
    return {
        tasks: props.tasks,
        modules: props.modules,
        profiles: props.profiles,
        proxies: props.proxies
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        dispatch
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(ModalWrapper);


//export default withToast(ModalWrapper);
