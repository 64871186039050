import React from 'react';

// Using react-bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';

import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';

import Card from 'react-bootstrap/Card';

const Settings = () => {
    
    return (
        <div className="container use-bootstrap">
            <Card className="text-center" bg="dark">
                <Card.Header>Settings</Card.Header>
                <Card.Body>
                    <Card.Title>Key Settings</Card.Title>
                    <Card.Text>
                        <Form>

                            

                                <Form.Group as={Row} controlId="formPlaintextPassword">
                                    <Form.Label column sm="2">
                                        2Captcha key
                                    </Form.Label>
                                    <Col sm="8">
                                    <Form.Control type="password" placeholder="Key" size="lg"/>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="success" size="lf" >Save</Button>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextPassword">
                                    <Form.Label column sm="2">
                                        Anti Captcha key
                                    </Form.Label>
                                    <Col sm="8">
                                    <Form.Control type="password" placeholder="Key" size="lg"/>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="success" size="lf" >Save</Button>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextPassword">
                                    <Form.Label column sm="2">
                                        Captcha Monster key
                                    </Form.Label>
                                    <Col sm="8">
                                    <Form.Control type="password" placeholder="Key" size="lg"/>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="success" size="lf" >Save</Button>
                                    </Col>
                                </Form.Group>

                                <Form.Group>
                                    <Button as="input" type="reset" value="Reset Key" />
                                </Form.Group>
                            

                        </Form>
                    </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted">{new Date().getDate()}/{new Date().getMonth()}/{ new Date().getFullYear()}</Card.Footer>
            </Card>
        </div>
    )
};

export default Settings;