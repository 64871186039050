// Modal Basic Layout on User Dash

// React Library
import React, {Component} from 'react';

// import toast notifications
import {ToastProvider, useToasts} from 'react-toast-notifications';

// Import Modal SCSS
import './scss/modalStyle.scss';

// Using react-bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';

import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import {connect} from "react-redux";

import tasks from "../../api/tasks";

class VerticallyCenteredModal extends Component {
    state = {
        selected_store: "",
        selected_mode: "",
        selected_profile:"",
        selected_proxy:"",
        selected_name:"",
        selected_product:"",
        selected_delay:"",
        selected_threads:"",
    }

    render() {


        const submitChange = (e) => {
            e.preventDefault()
            let task = {
                store:this.state.selected_store,
                mode:this.state.selected_mode,
                profile:this.state.selected_profile,
                proxy:this.state.selected_proxy,
                name:this.state.selected_name,
                product:this.state.selected_product,
                delay:parseInt(this.state.selected_delay),
                threads:parseInt(this.state.selected_threads),
            }
            console.log(task)
            this.props.dispatch(tasks.create(task))
        }
        const changeState = (e) => {
            const newValue = e.target.value
            this.setState({
                [e.target.id]: newValue
            })
        }

        const stores = this.props.modules.map((module) => {
                return (
                    <option key={module.store}>{module.store}</option>
                )
            }
        )

        const modes = this.props.modules.filter((module) => {
            return module.store === this.state.selected_store
        }).map((module) => {
            return(
                module.modes.map((mode) => {
                    return (
                        <option key={mode} >{mode}</option>
                    )
                })
            )
            }
        )

        const profiles = this.props.profiles.map((profile) => {
            return(
                <option key={profile}>{profile.name}</option>
            )
        })

        const proxies = this.props.proxies.map((proxy) => {
                return(
                    <option key={proxy}>{proxy.name}</option>
                )
            }
        )

        return (

            <div className="">
                <form>
                    <Modal
                        show={this.props.show}
                        onHide={this.props.onHide}

                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className="use-bootstrap"
                        style={{
                            "color": "black"
                        }}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Create a New Task
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body onChange={changeState} >
                            <h4>Task</h4>

                            <Container>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group controlId="selected_store">
                                            <Form.Label>Store</Form.Label>
                                            <Form.Control as="select" value={this.state.selected_store} >
                                                <option/>
                                                {stores}
                                            </Form.Control>

                                        </Form.Group>

                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="selected_mode">
                                            <Form.Label>Mode</Form.Label>
                                            <Form.Control as="select" value={this.state.selected_mode}>
                                                <option/>
                                                {modes}
                                            </Form.Control>
                                        </Form.Group>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group controlId="selected_profile">
                                            <Form.Label>Profile</Form.Label>
                                            <Form.Control as="select" value={this.state.selected_profile.name} >
                                                <option/>
                                                {profiles}
                                            </Form.Control>
                                        </Form.Group>

                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="selected_proxy">
                                            <Form.Label>Proxy</Form.Label>
                                            <Form.Control as="select">
                                                <option/>
                                                {proxies}
                                            </Form.Control>
                                        </Form.Group>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <br/>
                                        <Form.Label>Task Name</Form.Label>
                                        <Form.Control id="selected_name" type="text" placeholder="Product"/>
                                        <br/>
                                        <Form.Label>Product (PID)</Form.Label>
                                        <Form.Control id="selected_product" type="text" placeholder="Product"/>
                                        <br/>
                                        <Form.Label>Delay</Form.Label>
                                        <Form.Control id="selected_delay" type="number" placeholder="Delay"/>
                                        <br/>
                                        <Form.Label>Threads</Form.Label>
                                        <Form.Control id="selected_threads" type="number" placeholder="Threads"/>
                                    </Col>

                                </Row>
                            </Container>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button onClick={this.props.onHide} variant="warning">Close</Button>
                            <Button onClick={submitChange} variant="primary">Create</Button>
                        </Modal.Footer>
                    </Modal>
                </form>
            </div>

        );
    }
}

const ModalWrapper = (props) => {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <div className="use-bootstrap">
            <Button variant="primary" onClick={() => setModalShow(true)}>
                Create ➕
            </Button>

            <VerticallyCenteredModal
                {...props}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div>
    );
}


function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts()
        return (
            <Component {...props} {...toastFuncs} />
        )
    }
}

const mapStateToProps = (props) => {
    return {
        tasks: props.tasks,
        modules: props.modules,
        profiles: props.profiles,
        proxies: props.proxies
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        dispatch
    }
}



export default connect(mapStateToProps,mapDispatchToProps)(ModalWrapper);
