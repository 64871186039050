import React, { Component } from 'react';
import {BrowserRouter,Route} from "react-router-dom";

// Components


// Navbar
import HomeNavBar from './components/navbar/Navbar';

// Table
import Table from './components/table/Table';



// Profile Page
import Profiles from './components/profiles/Profiles';

// Monitor Page
import Monitor from './components/monitor/Monitor';

// Proxy Page
import Proxy from "./components/proxy/Proxy";

// Quick Tasks
import QuickTask from './components/quickTasks/QuickTask';

// Settings
import Settings from './components/settings/Settings';


// Main App
class App extends Component {

    state = {
        user: {
            name:"test#1010"
        }
    }

    AddUserFunc = (user) => {
        user.id = Math.random()
        let userList = [...this.state.Users,user]
        this.setState({
            Users: userList
        })
    }

    DeleteUserFunc = (id) =>{
        console.log("delete",id)
        let userList = this.state.Users.filter(user => {
            return user.id !== id
        })
        this.setState({
            Users: userList
        })
    }

    // Render XHTML
    render() {
        return (
            <div className="App">

                




                {/* React Router */}
                <BrowserRouter>
                {/* Layout (Home Nav-Bar) */}
                <HomeNavBar />

                <Route exact path="/"  component={Table} />
                <Route path="/profiles" component={Profiles} />
                <Route path="/monitor" component={Monitor} />
                <Route path="/proxy" component={Proxy} />
                <Route path="/quick" component={QuickTask} />
                <Route path="/settings" component={Settings} />

                </BrowserRouter>

                {/* Table */}
                {/* <Table/> */}

                {/* Profiles Page */}
                {/* <Profiles /> */}

                {/* Monitor Page */}
                {/* <Monitor /> */}
                

                


                


            </div>

        );
    }
}

export default App;