// React Library
import React, {Component} from 'react';

// Import SCSS
import './scss/tableStyle.scss';
import Row from './Row';
import {connect} from "react-redux";
import tasks from '../../api/tasks.js';
import profiles from "../../api/profiles";
import proxies from "../../api/proxies";
import modules from "../../api/modules";
 




class Table extends Component {
    state = {}

    componentDidMount() {
        this.props.dispatch(profiles.fetch())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
       // console.log(this.props)
    };

    
    


    render() {

        let Rows;
        Rows = this.props.profiles.map(profile => {
                return (
                    <Row profile={profile}/>
                )
            }
        )


        return (

                    <table className="gfg" id="task-main-table">

                        <thead>
                        <tr>
                            <th className="heading__cell desc__wide">Profile Name</th>
                            <th className="heading__cell">Address</th>
                            <th className="heading__cell">Actions</th>

                        </tr>
                        </thead>

                        <tbody>
                            {Rows}
                        </tbody>

                    </table>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        profiles: state.profiles
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        dispatch
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Table);