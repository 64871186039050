// React Library
import React, {Component} from 'react';

// Import SCSS
import './scss/proxyStyle.scss';
import {connect} from "react-redux";

// Import Modal
import ProxyModal from "../modal/proxyModal";
import ProxyTable from "./Table";
import proxies from "../../api/proxies";


class Proxy extends Component {

    componentDidMount() {
        this.props.dispatch(proxies.fetch())
    }

    render() {


        return (


            <div className="app-table container">
                <div className="center-table">
                    <h2>Proxy</h2>

                    <ProxyTable />

                    <ProxyModal />


                </div>

            </div>
        )
    }
}

const mapStateToProps = (props) => {
    return {
        tasks: props.tasks,
        modules: props.modules,
        profiles: props.profiles,
        proxies: props.proxies
    }
}


const mapDispatchToProps = (dispatch) => {
    return{
        dispatch
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Proxy);


