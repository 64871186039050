// React Library
import React from 'react';

//Import SCSS and JS Components
import './taskbarStyle.scss';

//Import Modal
import TaskForm from '../../modal/Modal';


const Taskbar = () => {
    return(
        <div className="taskbar-container">
            <div class="topnav">

                <a href="#">125 Tasks</a>

                <div className="modal-in-taskbar">

                    <TaskForm/>

                </div>

                <div className="topnav-right">
                    <a href="#search">Search 🔍</a>
                    <a href="#about">Edit ✍</a>
                    
                </div>

            </div>
        </div>
    )
};

export default Taskbar;
