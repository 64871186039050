// React Library
import React, {Component} from 'react';

// Import SCSS
import './scss/tableStyle.scss';
import './scss/rowStyle.scss';

import {Play, Square, Copy, Trash} from "react-feather";
import {connect} from "react-redux";
import profiles from "../../api/profiles";

class Row extends Component {

    render() {

        const deleteProfile = () =>{
            this.props.dispatch(profiles.del(this.props.profile))
        }
        console.log(this.props.profile)

        return (
            <tr>
                <td className="desc__wide">{this.props.profile.name}</td>
                <td>{this.props.profile.delivery_address.line_1}</td>
                <td>
                    <button className='row-button' onClick={() => {deleteProfile()}}><Trash/></button>
                </td>
            </tr>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        dispatch
    }
}

export default connect(null,mapDispatchToProps)(Row);