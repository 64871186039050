// New Modal Layout


// React Library
import React, {Component} from 'react';

// Using react-bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';

import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';

// Import SCSS
import "./scss/modalStyle.scss";
import proxies from "../../api/proxies";
import {connect} from "react-redux";


class VerticallyCenteredModal extends Component {
    state = {
        selected_name:"",
        selected_proxies:"",
    }
    render() {

        const submitChange = (e) => {
            e.preventDefault()

            let proxy = {
                name: this.state.selected_name,
                proxies: this.state.selected_proxies.split("\n"),
            }
            console.log(proxy)
            this.props.dispatch(proxies.create(proxy))
        }
        const changeState = (e) => {
            const newValue = e.target.value
            this.setState({
                [e.target.id]: newValue
            })
        }

        return (
            <div className="">
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="use-bootstrap"
                    style={{
                        "color": "black"
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Create a New Task
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body onChange={changeState}>
                        <h4>Task</h4>

                        <Container>
                            <Row>
                                <Col>
                                    <Form.Group controlId="selected_name">
                                        <Form.Label>Proxy Name</Form.Label>
                                        <Form.Control size="lg" type="text"  value={this.state.selected_name} placeholder="Name"/>
                                    </Form.Group>
                                    <Form.Group controlId="selected_proxies">
                                        <Form.Label>Proxies</Form.Label>
                                        <Form.Control as="textarea" value={this.state.selected_proxies} rows={14}/>
                                    </Form.Group>
                                </Col>
                            </Row>


                        </Container>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={this.props.onHide} variant="warning">Close</Button>
                        <Button onClick={submitChange} variant="primary">Create</Button>
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
  }

  function ProxyModal({dispatch}) {
    const [modalShow, setModalShow] = React.useState(false);
  
    return (
      <div className="use-bootstrap" >
        <Button variant="primary"  onClick={() => setModalShow(true)}>
          Create  ➕
        </Button>
  
        <VerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          dispatch={dispatch}
        />
      </div>
    );
  }


const mapStateToProps = (props) => {
    return {
        tasks: props.tasks,
        modules: props.modules,
        profiles: props.profiles,
        proxies: props.proxies
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        dispatch
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(ProxyModal);
