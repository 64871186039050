// React Library
import React, {Component} from 'react';

// Import SCSS
import './scss/tableStyle.scss';
import './scss/rowStyle.scss';

import {Play, Square, Copy, Trash} from "react-feather";
import {connect} from "react-redux";
import proxies from "../../api/proxies";

class Row extends Component {

    render() {

        const deleteProxy = () =>{
            this.props.dispatch(proxies.del(this.props.proxy))
        }


        return (
            <tr>
                <td className="desc__wide">{this.props.proxy.name}</td>
                <td>{this.props.proxy.proxies.length}</td>
                <td>
                    <button className='row-button' onClick={() => {deleteProxy()}}><Trash/></button>
                </td>
            </tr>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        dispatch
    }
}

export default connect(null,mapDispatchToProps)(Row);