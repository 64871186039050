// New Modal Layout


// React Library
import React, {Component} from 'react';

// Using react-bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';

import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';

// Import SCSS
import "./scss/modalStyle.scss";




function MyVerticallyCenteredModal(props) {
    return (
        <div className="">
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="use-bootstrap"
                style={{
                    "color":"black"
                }}
            >
                <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create a New Task
                </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                <h4>Task</h4>
                
                <Container>
                <Row>
                        <Col lg={6}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Monitor Proxy</Form.Label>
                            <Form.Control as="select">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </Form.Control>
                        </Form.Group>

                        </Col>
                        <Col lg={6}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Checkout Proxy</Form.Label>
                            <Form.Control as="select">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </Form.Control>
                        </Form.Group>

                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Store</Form.Label>
                            <Form.Control as="select">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </Form.Control>
                        </Form.Group>

                        </Col>
                        <Col lg={6}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Mode</Form.Label>
                            <Form.Control as="select">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </Form.Control>
                        </Form.Group>

                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Profile</Form.Label>
                            <Form.Control as="select">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </Form.Control>
                        </Form.Group>
                            
                        </Col>
                        <Col lg={6}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Proxy</Form.Label>
                            <Form.Control as="select">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </Form.Control>
                        </Form.Group>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <br />
                        <Form.Label>Product</Form.Label>
                        <Form.Control type="text" placeholder="Normal text" />
                        <br />
                        <Form.Label>Delay</Form.Label>
                        <Form.Control type="number" placeholder="Normal text" />
                        <br />
                        <Form.Label>Threads</Form.Label>
                        <Form.Control type="number" placeholder="Normal text" />
                        </Col>
                        
                    </Row>
                </Container>
                </Modal.Body>

                <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
      
    );
  }
  
  function App() {
    const [modalShow, setModalShow] = React.useState(false);
  
    return (
      <div className="use-bootstrap" >
        <Button variant="primary" onClick={() => setModalShow(true)}>
          Create  ➕
        </Button>
  
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>
    );
  }

export default App;
